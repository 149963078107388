<template>
  <div>
    <div v-if="field !== null">
      <template v-if="field.type.startsWith('Enum<')">
        <multiselect v-model="item" class="custom-dropdown-options enable-option-icon"
          :max-height="300"
          :options="getOptions(field)"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false"
          :disabled="disabled"
          @input="handleInput">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="item == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ props.option }}</span>
          </template>
        </multiselect>
      </template>
      <div v-if="field.type === 'String'">
          <b-form-input
            size="md"
            v-model="item"
            @input="handleInput"
            :readonly="disabled">
          </b-form-input>
      </div>
      <div class="d-flex" v-if="field.type === 'Float' || field.type === 'Double' || field.type === 'Integer' || field.type === 'Byte' || field.type === 'Long'">
        <b-form-input
          size="md"
          v-model="item"
          @input="handleInput"
          @keydown="numberKey"
          :readonly="disabled">
        </b-form-input>
      </div>
      <div class="d-flex filter-radio-buttons" v-if="field.type === 'Boolean'">
        <b-form-checkbox
          class="ml-1"
          :disabled="disabled"
          v-model="item"
          @input="handleInput">
          <span class="mr-2">{{ field.displayName }}</span>
          <span v-if="field.description">
            <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
            <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
              {{ field.description }}
            </b-popover>  
          </span>
        </b-form-checkbox>
      </div>
      <div class="d-flex" v-if="field.type === 'Date'">
        <b-form-datepicker
          v-model="item"
          @input="handleInput"
          class="date-height"
          today-button
          close-button
          hide-header
          :label-today-button="$t('date.today')"
          :label-close-button="$t('date.close')"
          today-button-variant="primary"
          close-button-variant="secondary"
          :disabled="disabled"
          boundary="viewport"
          :popper-opts="{ positionFixed: true }"
        >
          <template v-slot:button-content="{ }">
            <font-awesome-icon :icon="['far', 'calendar-days']" />
          </template>
        </b-form-datepicker>
      </div>

      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showItemError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.cannot_be_blank', [field.displayName]) }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showRuleError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.validation_failed', [field.regex]) }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showMinError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('fields.error.less_than_min', [field.min]) }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showMaxError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('fields.error.greater_than_max', [field.max]) }}
      </b-form-invalid-feedback>
    </div>    
  </div>
</template>


<script>
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import fromExponential from 'from-exponential';
import { processRegExp } from '@/helpers';
import Multiselect from 'vue-multiselect';
export default {
  name: 'CustomField',
  components: {
    Multiselect
  },
  props: {
    value: { type: [ String, Number, Boolean ], default: null },
    field: { type: Object, default: null },
    disabled: { type: Boolean, default: false},
    componentId: { type: String, default: null }
  },
  data() {
    return {
      item: null,
      dropdownLabel: ''
    }
  },
  watch: {
    value(newValue) {
      if (this.field.type === 'Date' &&
          newValue !== null) {
        this.item = moment(newValue).format('YYYY-MM-DD');
      }
      else if (this.field.type === 'Enum<Integer>' ||
               this.field.type === 'Enum<Long>' ||
               this.field.type === 'Enum<Byte>') {
        // need to make sure the value assigned to this.item is string for the multiselect selection to work properly as the option value is string.
        const values = this.field.regex.split('|');
        for (const value of values) {
          if (parseInt(value) === this.value) {
            this.item = value;
          }
        }        
      }
      else if (this.field.type === 'Enum<Float>' ||
               this.field.type === 'Enum<Double>') {
        // the conversion from json to object changes floats/doubles like 3.0 to 3
        // so we need to find the precision
        const values = this.field.regex.split('|');
        for (const value of values) {
          if (parseFloat(value) === this.value) {
            this.item = value;
          }
        }        
      }
      else if (newValue !== null &&
               (this.field.type === 'Float' ||
               this.field.type === 'Double')) {
        this.item = fromExponential(this.value);        
      }
      else {
        this.item = cloneDeep(newValue);
      }
      this.setDropdownLabelIfEnumType(this.item);
    }
  },
  mounted() {
    if (this.value !== null) {
      if (this.field.type === 'Date') {
        this.item = this.value !== 0 ? moment(this.value).format('YYYY-MM-DD') : null;
      }
      else if (this.field.type === 'Enum<Integer>' ||
               this.field.type === 'Enum<Long>' ||
               this.field.type === 'Enum<Byte>') {
        // need to make sure the value assigned to this.item is string for the multiselect selection to work properly as the option value is string.
        const values = this.field.regex.split('|');
        for (const value of values) {
          if (parseInt(value) === this.value) {
            this.item = value;
          }
        }        
      }
      else if (this.field.type === 'Enum<Float>' ||
               this.field.type === 'Enum<Double>') {
        // the conversion from json to object changes floats/doubles like 3.0 to 3
        // so we need to find the precision
        const values = this.field.regex.split('|');
        for (const value of values) {
          if (parseFloat(value) === this.value) {
            this.item = value;
          }
        }       
      }
      else if (this.field.type === 'Float' ||
               this.field.type === 'Double') {
        this.item = fromExponential(this.value);        
      }
      else {
        this.item = cloneDeep(this.value);
      }
    }
    else if (typeof this.field.def !== 'undefined' &&
             this.field.def !== null) {
      if (this.field.type === 'Date') {
        this.item = this.field.def !== 0 ? moment(this.field.def).format('YYYY-MM-DD') : null;
      }
      else {
        this.item = this.field.def;
      }
      
      // send the value back via v-model
      this.handleInput();
    }
    else {
      this.item = null;
    }
    this.setDropdownLabelIfEnumType(this.item);
  },
  computed: {
    showItemError() {
      return this.field.showError && this.field.showError && this.field.notNull && (this.item === null ||
        ((this.field.type === 'Long' || this.field.type === 'Integer' || this.field.type === 'Byte' || this.field.type === 'Float' || this.field.type === 'Double' || this.field.type === 'Boolean') && this.item === ''));
    },
    showRuleError() {
      let val = null;
      if (this.field.type === 'Long' ||
          this.field.type === 'Integer' ||
          this.field.type === 'Byte') {
        if (this.item === '') {
          val = 0;
        }
        val = parseInt(this.item);
      }
      else if (this.field.type === 'Float' ||
               this.field.type === 'Double' ||
               this.field.type === 'Enum<Float>' ||
               this.field.type === 'Enum<Double>') {
        if (this.item === '') {
          val = 0.0;
        }
        else {
          val = parseFloat(this.item);
        }
      }
      else if (this.field.type === 'Date') {
        if (this.item === '') {
          val = null;
        }
        else {
          val = moment(this.item).unix() * 1000;
        }
      }
      else {
        val = this.item;
      }
      return this.field.showError && !this.field.type.startsWith('Enum<') && this.field.regex && !RegExp(processRegExp(this.field.regex)).test(val)
    },
    showMinError() {
      return this.field.showError && (((this.field.type === 'Integer' || this.field.type === 'Long' || this.field.type === 'Byte') && parseInt(this.item) < this.field.min) ||
      ((this.field.type === 'Double' || this.field.type === 'Float') && parseFloat(this.item) < this.field.min) ||
      (this.field.type === 'String' && this.item !== null && this.item.length < this.field.min));
    },
    showMaxError() {
      return this.field.showError && (((this.field.type === 'Integer' || this.field.type === 'Long' || this.field.type === 'Byte') && parseInt(this.item) > this.field.max) ||
      ((this.field.type === 'Double' || this.field.type === 'Float') && parseFloat(this.item) > this.field.max) ||
      (this.field.type === 'String' && this.item !== null &&  this.item.length > this.field.max))
    },
    min() {
      if (this.field.min < -2147483647) {
        return -2147483647;
      }
      return this.field.min;
    },
    max() {
      if (this.field.max > 2147483647) {
        return 2147483647;
      }
      return this.field.max;    
    }
  },
  methods: {
    getOptions(field) {
      return field.regex.split('|');
    },
    isCharNumeric(charStr) {
      return /[a-zA-Z]/.test(charStr);
    },
    isKeyPressedAlpha(event) {
      const charStr = event.key;
      return charStr.length === 1 && this.isCharNumeric(charStr);
    },
    numberKey(event) {
      if (!event.ctrlKey && !event.metaKey && this.isKeyPressedAlpha(event)) {
        event.preventDefault();
      }
    },
    handleInput() {
      if (this.field.showError) {
        this.field.showError = false;
      }
      
      if (this.field.type === 'Long' ||
          this.field.type === 'Integer' ||
          this.field.type === 'Byte') {
        if (this.item === '') {
          this.item = '0';
        }
        else if (this.item === '-') {
          return;  // don't emit the incomplete number
        }
        this.$emit('input', parseInt(this.item));
      }
      else if (this.field.type === 'Float' ||
               this.field.type === 'Double' ||
               this.field.type === 'Enum<Float>' ||
               this.field.type === 'Enum<Double>') {
        if (this.item === '') {
          this.item = '0.0';
        }
        else if (this.item === '-') {
          return;  // don't emit the incomplete number
        }
        this.$emit('input', parseFloat(this.item));
      }
      else if (this.field.type === 'Date') {
        if (this.item === '') {
          this.item = null;
          this.$emit('input', this.item);
        }
        else {
          this.$emit('input', moment(this.item).unix() * 1000);
        }
      }
      else {
        this.$emit('input', this.item);
      }
    },
    dropdownItemClick(item) {
      this.item = item;
      this.dropdownLabel = item != null? '' + item : '';
      this.handleInput();
    },
    setDropdownLabelIfEnumType(item) {
      if (this.field.type.startsWith('Enum<')) {
        this.dropdownLabel = item != null? '' + item : '';
      }
    },
  }
}
</script>

<style lang="scss">
.maxwidth {
  max-width: 200px;
}

.today-check-container {
  height: 35px;
}

.today-check {
  margin-left: 4px;
}

.filter-radio-buttons {
  height: 35px;
  align-items: center;
}

.filter-radio-buttons .radio-group-class {
  top: 2px;
}

.date-height {
  height: 35px !important;
}

@media only screen and (max-width: 768px) {
  .date-height {
    height: auto !important;
  }
}
</style>